import { IUser } from 'app/containers/App/store/interface';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

const defaultImage = require('./assets/img/avatar.svg');

interface IProps {
  userInfo: IUser;
  logOut: () => void;
}

const UserView = (props: IProps) => {
  const dropdown = React.useRef(null);
  const [isVisible, toggleVisible] = React.useState(false);
  const listener = (e: any) => {
    if (dropdown.current && dropdown.current.contains(e.target)) {
      return;
    }
    toggleVisible(false);
    document.removeEventListener('click', listener);
  };
  const handleClick = (e: any) => {
    e.stopPropagation();
    toggleVisible(!isVisible);
    document.addEventListener('click', listener);
  };

  const { userInfo, logOut } = props;
  return (
    <div className={styles.userBlock} onClick={handleClick} ref={dropdown}>
      <p className={styles.userName}>
        {userInfo && userInfo.first_name} {userInfo && userInfo.last_name}
      </p>
      <div className={styles.userContent}>
        <img
          src={
            userInfo && userInfo.avatar && userInfo.avatar.small
              ? userInfo.avatar.small
              : defaultImage
          }
          alt="avatar"
          className={styles.userImage}
        />
        {isVisible && (
          <ul className={styles.userDropdown}>
            <li className={styles.userDropdownItem}>
              <NavLink
                to={{
                  pathname: `/profile/${userInfo?.id}`,
                  state: { fromUserView: true }
                }}
              >
                <button className={styles.button}>View profile</button>
              </NavLink>
            </li>
            <li className={styles.userDropdownItem}>
              <button onClick={logOut} className={styles.button}>
                Logout
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserView;
