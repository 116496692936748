// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorPicker_popover__3gjPR {\n  position: absolute;\n  z-index: 2; }\n\n.ColorPicker_cover__2ytOx {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0; }\n\n.ColorPicker_eye__o5b3q {\n  width: 26px;\n  height: 26px;\n  fill: #5a5858; }\n  .ColorPicker_eye_active__m-k13 {\n    fill: #328757; }\n", ""]);
// Exports
exports.locals = {
	"popover": "ColorPicker_popover__3gjPR",
	"cover": "ColorPicker_cover__2ytOx",
	"eye": "ColorPicker_eye__o5b3q",
	"eye_active": "ColorPicker_eye_active__m-k13"
};
module.exports = exports;
