// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_pic__1DQ9V {\n  width: 118px;\n  height: 118px;\n  background: #fffeff;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  box-sizing: border-box; }\n  .styles_pic__1DQ9V img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain; }\n", ""]);
// Exports
exports.locals = {
	"pic": "styles_pic__1DQ9V"
};
module.exports = exports;
