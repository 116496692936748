// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PhotoSelect_container__1P68b {\n  display: flex;\n  width: 100%;\n  min-width: 0; }\n\n.PhotoSelect_buttons__2lr52 {\n  display: flex;\n  flex-direction: column; }\n\n.PhotoSelect_pic__3rQuM {\n  display: flex;\n  flex-shrink: 0;\n  width: 118px;\n  height: 118px;\n  margin-bottom: 10px;\n  margin-right: 10px;\n  position: relative;\n  border: 1px solid #e7e7e7; }\n\n.PhotoSelect_input__2-ZvK {\n  opacity: 0;\n  height: 0;\n  width: 0; }\n\n.PhotoSelect_preview__3ZJxv {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  object-fit: cover; }\n  .PhotoSelect_preview_default__36jwD {\n    object-fit: none; }\n\n.PhotoSelect_label__1pYMR {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  opacity: 0; }\n  .PhotoSelect_label__1pYMR:hover {\n    opacity: 1; }\n\n.PhotoSelect_button__2Z9UF {\n  margin-bottom: 10px; }\n", ""]);
// Exports
exports.locals = {
	"container": "PhotoSelect_container__1P68b",
	"buttons": "PhotoSelect_buttons__2lr52",
	"pic": "PhotoSelect_pic__3rQuM",
	"input": "PhotoSelect_input__2-ZvK",
	"preview": "PhotoSelect_preview__3ZJxv",
	"preview_default": "PhotoSelect_preview_default__36jwD",
	"label": "PhotoSelect_label__1pYMR",
	"button": "PhotoSelect_button__2Z9UF"
};
module.exports = exports;
