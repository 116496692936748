// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_app_container__2kUiV {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  background: #f7f7f7; }\n\n.styles_app_content__3ioUI {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  height: 100vh;\n  width: 100%;\n  transition: 0.07s;\n  background-color: #f7f7f7; }\n\n.styles_block__3lh-U {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  overflow: hidden;\n  padding: 26px;\n  height: 100%;\n  position: relative; }\n", ""]);
// Exports
exports.locals = {
	"app_container": "styles_app_container__2kUiV",
	"app_content": "styles_app_content__3ioUI",
	"block": "styles_block__3lh-U"
};
module.exports = exports;
