// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Typography_typography__25IFl {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal; }\n\n.Typography_noWrap__28fq9 {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap; }\n\n.Typography_align_left__enMpf {\n  text-align: left; }\n\n.Typography_align_center__3tZNI {\n  text-align: center; }\n\n.Typography_align_right__3TT9Z {\n  text-align: right; }\n\n.Typography_margin__2bo6o {\n  margin-bottom: 12px; }\n\n.Typography_variant_default__3iihJ {\n  font-size: 14px;\n  line-height: 16px; }\n\n.Typography_variant_secondary__3SLrg {\n  font-size: 12px;\n  line-height: 14px; }\n\n.Typography_variant_h3__1ufwr {\n  font-weight: bold;\n  font-size: 50px;\n  line-height: 59px; }\n\n.Typography_variant_h5__1nNW3 {\n  font-size: 24px;\n  line-height: 28px; }\n\n.Typography_variant_h6__ZwMMc {\n  font-family: Roboto-Bold;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 21px; }\n\n.Typography_variant_h7__3z5_J {\n  font-family: Roboto-Bold;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 19px; }\n\n.Typography_color_default__3VnEb {\n  color: #3e3e3e; }\n\n.Typography_color_contrast__7QCBR {\n  color: white; }\n\n.Typography_color_textSecondary__2Hs1V {\n  color: #9b9b9b; }\n\n.Typography_color_error__2KJMt {\n  color: #db4437; }\n\n.Typography_pointer__3LcJS {\n  cursor: pointer; }\n\n.Typography_left_gap__2LM6L {\n  margin-left: 10px; }\n", ""]);
// Exports
exports.locals = {
	"typography": "Typography_typography__25IFl",
	"noWrap": "Typography_noWrap__28fq9",
	"align_left": "Typography_align_left__enMpf",
	"align_center": "Typography_align_center__3tZNI",
	"align_right": "Typography_align_right__3TT9Z",
	"margin": "Typography_margin__2bo6o",
	"variant_default": "Typography_variant_default__3iihJ",
	"variant_secondary": "Typography_variant_secondary__3SLrg",
	"variant_h3": "Typography_variant_h3__1ufwr",
	"variant_h5": "Typography_variant_h5__1nNW3",
	"variant_h6": "Typography_variant_h6__ZwMMc",
	"variant_h7": "Typography_variant_h7__3z5_J",
	"color_default": "Typography_color_default__3VnEb",
	"color_contrast": "Typography_color_contrast__7QCBR",
	"color_textSecondary": "Typography_color_textSecondary__2Hs1V",
	"color_error": "Typography_color_error__2KJMt",
	"pointer": "Typography_pointer__3LcJS",
	"left_gap": "Typography_left_gap__2LM6L"
};
module.exports = exports;
