// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Label_field_label__17wxG {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n  margin: 10px 0;\n  margin-top: 10px;\n  color: #9b9b9b; }\n", ""]);
// Exports
exports.locals = {
	"field_label": "Label_field_label__17wxG"
};
module.exports = exports;
