import cx from 'classnames';
import * as React from 'react';

import styles from './Table.module.scss';

export const TablePanel: React.FC = props => (
  <div className={styles.table_panel} {...props} />
);

export const TableFilters: React.FC = props => (
  <div className={styles.table_panel_filters} {...props} />
);

export interface TableFilterProps {
  right: boolean;
  left: boolean;
  search: boolean;
  minSearch: boolean;
}

export const TableFilter: React.FC<Partial<TableFilterProps>> = ({
  left,
  right,
  search,
  minSearch,
  ...other
}) => (
  <div
    className={cx({
      [styles.table_panel_filter]: true,
      [styles.table_panel_filter_search]: search,
      [styles.table_panel_filter_minSearch]: minSearch,
      [styles.table_panel_filter_left]: left,
      [styles.table_panel_filter_right]: right
    })}
    {...other}
  />
);

export const TablePanelSeparator: React.FC = props => (
  <div className={styles.table_panel_separator} {...props} />
);
