// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IconButton_icon_button__2h9vw {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  flex-grow: 0;\n  padding: 10px;\n  border-radius: 50%;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"icon_button": "IconButton_icon_button__2h9vw"
};
module.exports = exports;
