// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_recoveryForm__2-PYd {\n  height: 400px;\n  margin-top: 264px;\n  margin-left: 60px; }\n\n.styles_back__1LN24 {\n  display: flex;\n  margin-bottom: 22px; }\n\n.styles_backImg__2XxsZ {\n  width: 22px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  .styles_backImg__2XxsZ img {\n    width: 9px; }\n\n.styles_title__7GVvf {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px; }\n", ""]);
// Exports
exports.locals = {
	"recoveryForm": "styles_recoveryForm__2-PYd",
	"back": "styles_back__1LN24",
	"backImg": "styles_backImg__2XxsZ",
	"title": "styles_title__7GVvf"
};
module.exports = exports;
