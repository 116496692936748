// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Group_group__1BUD8 {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  background: #f7f7f7;\n  padding: 14px;\n  flex-shrink: 0;\n  border-radius: 4px;\n  position: relative; }\n  .Group_group_column__eXU9H {\n    display: flex;\n    flex-direction: column;\n    flex: 1 1;\n    padding: 0 10px;\n    width: 100%; }\n  .Group_group_row__3--5f {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    flex-shrink: 0; }\n    .Group_group_row__3--5f > * {\n      margin-left: 10px;\n      margin-right: 10px; }\n      .Group_group_row__3--5f > *:first-child {\n        margin-left: 0; }\n      .Group_group_row__3--5f > *:last-child {\n        margin-right: 0; }\n", ""]);
// Exports
exports.locals = {
	"group": "Group_group__1BUD8",
	"group_column": "Group_group_column__eXU9H",
	"group_row": "Group_group_row__3--5f"
};
module.exports = exports;
