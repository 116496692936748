// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_modalContent__UsfB0 {\n  position: absolute;\n  top: calc(50% - 92px);\n  left: calc(50% - 145px);\n  width: 290px;\n  height: 184px;\n  background-color: white;\n  outline: none;\n  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3);\n  border-radius: 4px;\n  overflow: auto;\n  z-index: 1000; }\n  .styles_modalContent_big__3zepc {\n    width: 390px;\n    height: 234px; }\n\n.styles_modalOverlay__1i7Cu {\n  z-index: 999;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.4); }\n", ""]);
// Exports
exports.locals = {
	"modalContent": "styles_modalContent__UsfB0",
	"modalContent_big": "styles_modalContent_big__3zepc",
	"modalOverlay": "styles_modalOverlay__1i7Cu"
};
module.exports = exports;
