import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { waitComponent } from 'app/components/AppRouter';
import App from 'app/containers/App';
import { ConnectedRouter } from 'connected-react-router';
import saga from 'core/sagas';
import { configureStore, history } from 'core/store/configureStore';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const Reports = React.lazy(() => import('reports/Reports'));

const Favicon = require('react-favicon');
const favicon = require('./assets/favicon/favicon.ico');

export const store = configureStore();
store.runSaga(saga);

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[800],
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <Favicon url={favicon} />
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <React.Suspense fallback={null}>
              <Switch>
                <Route path="/reports" render={waitComponent(Reports)} />
                <Route path="/" render={waitComponent(App)} />
              </Switch>
            </React.Suspense>
          </ConnectedRouter>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById('app')
);
