// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/img/photo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".styles_login__EMYLa {\n  display: flex;\n  width: 100%;\n  height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  margin: 0; }\n\n.styles_logo__cPJF3 {\n  position: absolute;\n  width: 92px;\n  left: 60px;\n  top: 60px; }\n\n.styles_form__yWc7- {\n  min-width: 462px;\n  background: #ffffff;\n  position: relative;\n  height: 100%;\n  overflow: auto; }\n\n.styles_welcome__mVMER {\n  width: 100%;\n  background: rgba(43, 112, 73, 0.83); }\n\n.styles_welcome_title__1sTCm {\n  font-family: Roboto-Thin;\n  font-size: 50px;\n  line-height: 59px;\n  color: #ffffff;\n  margin-bottom: 16px;\n  margin-top: 346px;\n  padding-left: 60px; }\n  .styles_welcome_title__1sTCm span {\n    font-family: Roboto-Bold; }\n\n.styles_welcome_text__itDYn {\n  font-family: Roboto-Light;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 34px;\n  color: #ffffff;\n  padding-left: 60px; }\n", ""]);
// Exports
exports.locals = {
	"login": "styles_login__EMYLa",
	"logo": "styles_logo__cPJF3",
	"form": "styles_form__yWc7-",
	"welcome": "styles_welcome__mVMER",
	"welcome_title": "styles_welcome_title__1sTCm",
	"welcome_text": "styles_welcome_text__itDYn"
};
module.exports = exports;
