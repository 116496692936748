import cx from 'classnames';
import * as React from 'react';

import Header from '../Header';
import Navigation from '../Navigation';
import styles from './styles.module.scss';

interface IProps {
  children: any;
}

const AppContainer = (props: IProps) => {
  const [isShort, toggleShort] = React.useState(false);
  const { children } = props;
  return (
    <div className={styles.app_container}>
      <Navigation toggleShort={toggleShort} isShort={isShort}></Navigation>
      <div
        className={cx(styles.app_content, {
          [styles.app_content_wide]: isShort
        })}
      >
        <Header></Header>
        <div className={styles.block}>{children}</div>
      </div>
    </div>
  );
};

export default AppContainer;
