import cx from 'classnames';
import * as React from 'react';

import styles from './styles.module.scss';

export interface PaperProps {
  divRef: any;
  padding: string;
}

export const Paper: React.FC<Partial<PaperProps>> = ({
  divRef,
  padding = 'normal',
  ...other
}) => (
  <div
    ref={divRef}
    className={cx({
      [styles.paper]: true,
      [styles['paper_padding_' + padding]]: true
    })}
    {...other}
  ></div>
);

export default Paper;
