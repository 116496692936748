import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';

import * as constants from './constants';
import {
  IEditProfileReducer,
  IUserProfileReducer,
  IUsers,
  IUsersReducer
} from './interfaces';

export const actions = {
  getUsersInfo: createAction(constants.GET_USERS_INFO),
  getUsersInfoSuccess: createAction(constants.GET_USERS_INFO_SUCCESS),
  getUsersInfoFailure: createAction(constants.GET_USERS_INFO_FAILURE),
  showEditProfile: createAction(constants.SHOW_EDIT_PROFILE),
  backToProfile: createAction(constants.BACK_TO_PROFILE),
  backToEditProfile: createAction(constants.BACK_TO_EDIT_PROFILE),
  changeUsersName: promiseAction(constants.CHANGE_USERS_NAME),
  changeUsersNameSuccess: createAction(constants.CHANGE_USERS_NAME_SUCCESS),
  changeUsersNameFailure: createAction(constants.CHANGE_USERS_NAME_FAILURE),
  getPasswordCode: createAction(constants.GET_PASSWORD_CODE),
  getPasswordCodeSuccess: createAction(constants.GET_PASSWORD_CODE_SUCCESS),
  getPasswordCodeFailure: createAction(constants.GET_PASSWORD_CODE_FAILURE),
  sendPasswordCode: createAction(constants.SEND_PASSWORD_CODE),
  sendPasswordCodeSuccess: createAction(constants.SEND_PASSWORD_CODE_SUCCESS),
  sendPasswordCodeFailure: createAction(constants.SEND_PASSWORD_CODE_FAILURE),
  finishChangePassword: createAction(constants.FINISH_CHANGE_PASSWORD),
  finishChangePasswordSuccess: createAction(
    constants.FINISH_CHANGE_PASSWORD_SUCCESS
  ),
  finishChangePasswordFailure: createAction(
    constants.FINISH_CHANGE_PASSWORD_FAILURE
  ),
  getUsers: createAction(constants.GET_USERS),
  getUsersSuccess: createAction(constants.GET_USERS_SUCCESS),
  getUsersFailure: createAction(constants.GET_USERS_FAILURE),
  clearUsers: createAction(constants.CLEAR_USERS),
  clearUser: createAction(constants.CLEAR_USER),
  getAvatar: createAction(constants.GET_AVATAR),
  getAvatarSuccess: createAction(constants.GET_AVATAR_SUCCESS),
  getAvatarFailure: createAction(constants.GET_AVATAR_FAILURE),
  uploadAvatar: createAction(constants.UPLOAD_AVATAR),
  uploadAvatarSuccess: createAction(constants.UPLOAD_AVATAR_SUCCESS),
  uploadAvatarFailure: createAction(constants.UPLOAD_AVATAR_FAILURE),
  deleteAvatar: createAction(constants.DELETE_AVATAR),
  deleteAvatarSuccess: createAction(constants.DELETE_AVATAR_SUCCESS),
  deleteAvatarFailure: createAction(constants.DELETE_AVATAR_FAILURE),
  addUser: createAction(constants.ADD_USER),
  addUserSuccess: createAction(constants.ADD_USER_SUCCESS),
  addUserFailure: createAction(constants.ADD_USER_FAILURE),
  addUserId: createAction(constants.ADD_USER_ID),
  clearUserId: createAction(constants.CLEAR_USER_ID)
};

const usersIsLoading = (state = false, action: PayloadedAction): boolean => {
  switch (action.type) {
    case constants.GET_USERS:
    case constants.GET_USERS_INFO:
      return true;
    case constants.GET_USERS_SUCCESS:
    case constants.GET_USERS_FAILURE:
    case constants.GET_USERS_INFO_SUCCESS:
    case constants.GET_USERS_INFO_FAILURE:
      return false;
    default:
      return state;
  }
};

const userId = (state = null, action: PayloadedAction): number => {
  switch (action.type) {
    case constants.ADD_USER_ID:
      return action.payload;
    case constants.CLEAR_USER_ID:
    case constants.ADD_USER_SUCCESS:
      return null;
    default:
      return state;
  }
};

const initialStateUserProfile = {
  successGetUsersInfo: null,
  failureGetUsersInfo: null
};

export const userProfileReducer = (
  state = initialStateUserProfile,
  action: PayloadedAction
): IUserProfileReducer => {
  switch (action.type) {
    case constants.GET_USERS_INFO_SUCCESS:
      return {
        ...state,
        successGetUsersInfo: action.payload
      };

    case constants.GET_USERS_INFO_FAILURE:
      return {
        ...state,
        failureGetUsersInfo: action.payload
      };

    default:
      return state;
  }
};

const initialStateEditProfile = {
  loader: false,
  editProfileView: false,
  successChangeUsersName: null,
  failureChangeUsersName: null,
  editStep: 1,
  successGetPasswordCode: null,
  failureGetPasswordCode: null,
  successSendPasswordCode: null,
  failureSendPasswordCode: null,
  successFinishChangePassword: null,
  failureFinishChangePassword: null,
  successGetAvatar: null,
  failureGetAvatar: null,
  successUploadAvatar: null,
  failureUploadAvatar: null,
  successDeleteAvatar: null,
  failureDeleteAvatar: null,
  successAddUser: null,
  failureAddUser: null
};

export const editProfileReducer = (
  state = initialStateEditProfile,
  action: PayloadedAction
): IEditProfileReducer => {
  switch (action.type) {
    case constants.CLEAR_USER:
      return {
        ...state,
        failureChangeUsersName: null,
        failureGetPasswordCode: null,
        failureSendPasswordCode: null,
        failureFinishChangePassword: null,
        failureGetAvatar: null,
        failureUploadAvatar: null,
        failureDeleteAvatar: null,
        failureAddUser: null
      };
    case constants.SHOW_EDIT_PROFILE:
      return {
        ...state,
        editProfileView: true
      };
    case constants.BACK_TO_EDIT_PROFILE:
      return {
        ...state,
        editStep: 1,
        failureSendPasswordCode: null,
        failureFinishChangePassword: null
      };

    case constants.BACK_TO_PROFILE:
      return {
        ...state,
        editStep: 1,
        editProfileView: false,
        failureSendPasswordCode: null,
        failureFinishChangePassword: null
      };
    case constants.CHANGE_USERS_NAME:
      return {
        ...state,
        loader: true,
        failureChangeUsersName: null,
      };
    case constants.CHANGE_USERS_NAME_SUCCESS:
      return {
        ...state,
        successChangeUsersName: action.payload,
        loader: false,
        editProfileView: false
      };

    case constants.CHANGE_USERS_NAME_FAILURE:
      return {
        ...state,
        failureChangeUsersName: action.payload,
        loader: false
      };

    case constants.ADD_USER:
      return {
        ...state,
        loader: true,
        failureAddUser: null,
      };
    case constants.ADD_USER_SUCCESS:
      return {
        ...state,
        successAddUser: action.payload,
        failureAddUser: null,
        loader: false
      };

    case constants.ADD_USER_FAILURE:
      return {
        ...state,
        failureAddUser: action.payload,
        loader: false
      };

    case constants.GET_AVATAR_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          successGetAvatar: action.payload.upload_link
        };
      }
      break;
    case constants.GET_AVATAR_FAILURE:
      return {
        ...state,
        failureGetAvatar: action.payload
      };

    case constants.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        successUploadAvatar: action.payload
      };

    case constants.UPLOAD_AVATAR_FAILURE:
      return {
        ...state,
        failureUploadAvatar: action.payload
      };

    case constants.DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        successDeleteAvatar: action.payload
      };

    case constants.DELETE_AVATAR_FAILURE:
      return {
        ...state,
        failureDeleteAvatar: action.payload
      };
    case constants.GET_PASSWORD_CODE:
      return {
        ...state,
        loader: true,
        failureGetPasswordCode: null
      };
    case constants.GET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        successGetPasswordCode: action.payload,
        loader: false,
        editStep: 2
      };

    case constants.GET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        failureGetPasswordCode: action.payload,
        loader: false
      };

    case constants.SEND_PASSWORD_CODE:
      return {
        ...state,
        loader: true
      };
    case constants.SEND_PASSWORD_CODE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          successSendPasswordCode: action.payload.change_password_token,
          loader: false,
          editStep: 3
        };
      }
      break;
    case constants.SEND_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        failureSendPasswordCode: action.payload,
        loader: false
      };

    case constants.FINISH_CHANGE_PASSWORD:
      return {
        ...state,
        loader: true
      };
    case constants.FINISH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        successFinishChangePassword: action.payload,
        loader: false,
        editStep: 1
      };

    case constants.FINISH_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        failureFinishChangePassword: action.payload,
        loader: false
      };
    default:
      return state;
  }
};

const initialStateUsers = {
  usersData: {
    count: 0,
    offset: 0,
    limit: 100,
    search: '',
    next: '',
    sortBy: '',
    role: 'admin,engineer,inspector,client',
    previous: '',
    results: [],
    isEnd: false,
  },
  failure: null,
};

export const usersReducer = (
  state = initialStateUsers,
  action: PayloadedAction
): IUsers => {
  switch (action.type) {
    case constants.GET_USERS_SUCCESS:
      const isEndStatus = !!action.payload.results.length;
      return {
        ...state,
        usersData: {
        ...action.payload,
        results: [...state.usersData.results, ...action.payload.results],
          isEnd: isEndStatus,
        },
      };

    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.CLEAR_USERS:
      return {
        ...state,
        usersData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          sortBy: '',
          role: 'admin,engineer,inspector,client',
          previous: '',
          results: [],
          isEnd: false,
        },
      };
    default:
      return state;
  }
};

const usersReducers = combineReducers<IUsersReducer>({
  usersIsLoading,
  userProfileReducer,
  editProfileReducer,
  usersReducer,
  userId
});

export default usersReducers;
