// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__VOqJE {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0; }\n  .styles_container_margin__3h6O3 {\n    margin-bottom: 20px; }\n\n.styles_field__14Wdf {\n  display: flex;\n  width: 100%;\n  background: white;\n  border: 1px solid #e7e7e7;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.styles_field__14Wdf:focus-within {\n  border-color: #328757; }\n\n.styles_error__11Nnd {\n  border-color: #db4437; }\n\n.styles_field_input__1CsT4 {\n  padding: 16px;\n  color: #3e3e3e;\n  font-size: 14px;\n  line-height: 16px;\n  width: 100%;\n  min-width: 40px;\n  height: 46px; }\n\n.styles_adornment__2RZVZ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 46px;\n  min-width: 46px;\n  flex-shrink: 0;\n  flex-grow: 0; }\n\n.styles_errorBlock__3SJxQ {\n  display: flex;\n  justify-content: space-between; }\n\n.styles_errorText__31G0k {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  /* identical to box height */\n  text-align: center;\n  color: #db4437;\n  margin: 12px; }\n\n.styles_errorImg__TKLV9 {\n  width: 16px;\n  height: 18px;\n  margin: 12px; }\n\n.styles_checkboxContainer__WVJYv {\n  display: inline-block;\n  vertical-align: middle; }\n\n.styles_hiddenCheckbox__3Tk4a {\n  border: 0;\n  clip: rect(0 0 0 0);\n  clippath: inset(50%);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px; }\n\n.styles_styledCheckbox__3wz30 {\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  margin-right: 12px;\n  transition: all 150ms; }\n  .styles_styledCheckbox__3wz30 img {\n    border-radius: 0; }\n", ""]);
// Exports
exports.locals = {
	"container": "styles_container__VOqJE",
	"container_margin": "styles_container_margin__3h6O3",
	"field": "styles_field__14Wdf",
	"error": "styles_error__11Nnd",
	"field_input": "styles_field_input__1CsT4",
	"adornment": "styles_adornment__2RZVZ",
	"errorBlock": "styles_errorBlock__3SJxQ",
	"errorText": "styles_errorText__31G0k",
	"errorImg": "styles_errorImg__TKLV9",
	"checkboxContainer": "styles_checkboxContainer__WVJYv",
	"hiddenCheckbox": "styles_hiddenCheckbox__3Tk4a",
	"styledCheckbox": "styles_styledCheckbox__3wz30"
};
module.exports = exports;
