import cx from 'classnames';
import * as React from 'react';

import styles from './styles.module.scss';

export interface PreloaderProps {
  size: 'default' | 'button';
}

const Preloader: React.SFC<Partial<PreloaderProps>> = ({
  size = 'default'
}) => {
  return (
    <div className={styles.container}>
      <div className={cx(styles.loader, styles['loader_size_' + size])}></div>
    </div>
  );
};

export const LoaderWrapper: React.FC<Partial<
  React.HTMLAttributes<HTMLDivElement>
>> = props => <div className={styles.wrapper} {...props} />;

export default Preloader;
