// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_paper__1worQ {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  height: 100%;\n  background-color: #fff;\n  overflow: auto; }\n\n.styles_paper_padding_none__1s8ly {\n  padding: 0; }\n\n.styles_paper_padding_normal__2xolO {\n  padding: 32px; }\n", ""]);
// Exports
exports.locals = {
	"paper": "styles_paper__1worQ",
	"paper_padding_none": "styles_paper_padding_none__1s8ly",
	"paper_padding_normal": "styles_paper_padding_normal__2xolO"
};
module.exports = exports;
