import cx from 'classnames';
import React from 'react';

import styles from './IconButton.module.scss';

export interface IconButtonProps {
  component: any;
  className: string;
  onClick: (any) => void;
}

export const IconButton: React.FC<Partial<IconButtonProps>> = ({
  component: Component = 'button',
  className,
  ...other
}) => <Component className={cx(styles.icon_button, className)} {...other} />;

export default IconButton;
