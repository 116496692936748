// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_field_text__3RpTB {\n  font-size: 14px;\n  line-height: 16px;\n  color: #3e3e3e; }\n", ""]);
// Exports
exports.locals = {
	"field_text": "styles_field_text__3RpTB"
};
module.exports = exports;
