// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_modalContent__1Q4s3 {\n  display: flex;\n  flex-direction: column;\n  padding: 24px; }\n\n.styles_buttonsBlock__y1HO3 {\n  display: flex;\n  justify-content: flex-end; }\n\n.styles_title__3_fca {\n  font-family: Roboto-Bold;\n  font-size: 16px;\n  line-height: 24px;\n  color: #2c2c2c;\n  margin-bottom: 12px; }\n\n.styles_subTitle__1ygS2 {\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #3e3e3e;\n  margin-bottom: 34px; }\n\n.styles_cancelButton__2HA-X {\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.0357143em;\n  color: #9b9b9b;\n  margin-right: 40px; }\n\n.styles_submitButton__CVYdT {\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.0357143em;\n  color: #f44336; }\n  .styles_submitButton_active__nw75Q {\n    color: #328757; }\n", ""]);
// Exports
exports.locals = {
	"modalContent": "styles_modalContent__1Q4s3",
	"buttonsBlock": "styles_buttonsBlock__y1HO3",
	"title": "styles_title__3_fca",
	"subTitle": "styles_subTitle__1ygS2",
	"cancelButton": "styles_cancelButton__2HA-X",
	"submitButton": "styles_submitButton__CVYdT",
	"submitButton_active": "styles_submitButton_active__nw75Q"
};
module.exports = exports;
