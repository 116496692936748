import { actions } from 'app/containers/App/store/duck';
import axios from 'axios';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';

//import { cacheAdapterEnhancer } from 'axios-extensions';
import { store } from '../../index';

const accessToken = localStorage.getItem('GIRToken');

const ciApi = process.env.REACT_APP_URL;
const baseURL = ciApi ? ciApi : 'https://dev.girsinspection.com/api/v1';
// 'https://stage-app.girsinspection.com/api/v1';
const headers: Partial<{
  'content-type': string;
  Authorization: string;
}> = {
  'content-type': 'application/json',
};

if (accessToken) headers.Authorization = `JWT ${accessToken.replace(/"/g, '')}`;

export let api = axios.create({
  baseURL,
  timeout: 300000,
  headers,
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
  //   enabledByDefault: false
  // })
});

configure({
  axios: api,
  cache: new LRU(500),
});

api.interceptors.request.use((request) => {
  const { Authorization, ...other } = request.headers;
  request.headers = other;

  const accessToken = localStorage.getItem('GIRToken');
  if (accessToken) request.headers.Authorization = 'JWT ' + accessToken;
  return request;
});

api.interceptors.response.use(
  function (response) {
    if (response.data.access) {
      writeAccessToken(response.data.access);
    }
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      return resetToken(error);
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function isTokenExpiredError(errorResponse) {
  // debugger;
  if (errorResponse?.status === 401) {
    return true;
  }
  return false;
}

let requiests = [];
let fetchingToken = false;

async function resetToken(error) {
  try {
    if (!localStorage.getItem('GIRRefreshToken')) throw error;

    const retryOriginalRequest = new Promise((resolve) => {
      requiests.push((access_token) => {
        error.response.config.headers.Authorization = `JWT ` + access_token;
        resolve(axios(error.response.config));
      });
    });

    if (!fetchingToken) {
      fetchingToken = true;

      const response = await axios({
        baseURL,
        method: 'post',
        url: `/auth/refresh/`,
        data: {
          refresh: localStorage.getItem('GIRRefreshToken'),
        },
      });
      if (!response.data) throw error;

      const { access, refresh } = response.data;
      localStorage.setItem('GIRToken', access);
      localStorage.setItem('GIRRefreshToken', refresh);
      writeAccessToken(access);
      fetchingToken = false;

      requiests.forEach((callback) => callback(access));
      requiests = [];
    }

    return retryOriginalRequest;
  } catch (err) {
    store.dispatch(actions.logOut());
    return Promise.reject(error);
  }
}

export function writeAccessToken(access) {
  if (access)
    Object.assign(api.defaults, {
      headers: { Authorization: `JWT ${access}` },
    });
  localStorage.setItem('GIRToken', access);
}
