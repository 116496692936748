// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PlatformComponent_edit__2hXuy {\n  width: 24px !important;\n  height: 24px !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 0 !important;\n  margin-right: 5px;\n  opacity: 0; }\n  .PlatformComponent_edit__2hXuy svg {\n    fill: #3e3e3e; }\n\n.PlatformComponent_navLink__1Ntnc:hover {\n  cursor: pointer;\n  background-color: #d9f5e7; }\n  .PlatformComponent_navLink__1Ntnc:hover > div {\n    background-color: #d9f5e7; }\n    .PlatformComponent_navLink__1Ntnc:hover > div .PlatformComponent_edit__2hXuy {\n      opacity: 1; }\n      .PlatformComponent_navLink__1Ntnc:hover > div .PlatformComponent_edit__2hXuy svg {\n        fill: #328757; }\n\n.PlatformComponent_navLink__1Ntnc:nth-child(even):hover {\n  background-color: #d9f5e7; }\n  .PlatformComponent_navLink__1Ntnc:nth-child(even):hover > div {\n    background-color: #d9f5e7; }\n\n.PlatformComponent_back__HuAwc {\n  display: flex;\n  align-items: baseline; }\n\n.PlatformComponent_backImg__13Hv6 {\n  height: 16px;\n  width: 10px;\n  margin-right: 10px;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"edit": "PlatformComponent_edit__2hXuy",
	"navLink": "PlatformComponent_navLink__1Ntnc",
	"back": "PlatformComponent_back__HuAwc",
	"backImg": "PlatformComponent_backImg__13Hv6"
};
module.exports = exports;
