// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_back__34tpM {\n  display: flex;\n  align-items: baseline; }\n\n.styles_backImg__1A2sE {\n  cursor: pointer; }\n\n.styles_submitButton__388al {\n  width: 342px; }\n\n.styles_title__M9_8c {\n  font-family: Roboto-Bold;\n  font-size: 24px;\n  line-height: 28px;\n  color: #3e3e3e;\n  margin-left: 20px;\n  margin-bottom: 22px; }\n\n.styles_profile__2npQy {\n  height: 606px;\n  min-width: 460px;\n  padding: 30px;\n  background: #ffffff; }\n\n.styles_field__2NPrK {\n  margin-bottom: 40px; }\n  .styles_field__2NPrK .styles_text__1mi7X {\n    font-size: 14px;\n    line-height: 24px;\n    letter-spacing: 0.15px;\n    color: #9b9b9b;\n    margin-bottom: 18px; }\n  .styles_field__2NPrK .styles_subtext__2Y7uv {\n    width: 342px;\n    font-size: 14px;\n    line-height: 16px;\n    color: #3e3e3e; }\n\n.styles_error__EYYEw {\n  font-size: 12px;\n  line-height: 16px;\n  color: #f44336;\n  margin-bottom: 16px; }\n", ""]);
// Exports
exports.locals = {
	"back": "styles_back__34tpM",
	"backImg": "styles_backImg__1A2sE",
	"submitButton": "styles_submitButton__388al",
	"title": "styles_title__M9_8c",
	"profile": "styles_profile__2npQy",
	"field": "styles_field__2NPrK",
	"text": "styles_text__1mi7X",
	"subtext": "styles_subtext__2Y7uv",
	"error": "styles_error__EYYEw"
};
module.exports = exports;
