// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_field__P3lNE {\n  margin-bottom: 40px; }\n\n.styles_field_avatar__amOb3 {\n  width: 120px;\n  height: 120px;\n  min-height: 120px;\n  margin-bottom: 60px; }\n", ""]);
// Exports
exports.locals = {
	"field": "styles_field__P3lNE",
	"field_avatar": "styles_field_avatar__amOb3"
};
module.exports = exports;
