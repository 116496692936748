// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_loginForm__1k-wZ {\n  width: 348px;\n  height: 400px;\n  margin-top: 265px;\n  margin-left: 60px; }\n\n.styles_title__1F3qT {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-bottom: 22px; }\n\n.styles_link__3hmZI {\n  cursor: pointer;\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 16px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #328757; }\n\n.styles_error__BBo9P {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px; }\n\n.styles_submitButton__ivrmY {\n  margin: 16px 0 20px 0; }\n", ""]);
// Exports
exports.locals = {
	"loginForm": "styles_loginForm__1k-wZ",
	"title": "styles_title__1F3qT",
	"link": "styles_link__3hmZI",
	"error": "styles_error__BBo9P",
	"submitButton": "styles_submitButton__ivrmY"
};
module.exports = exports;
