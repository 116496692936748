import * as React from 'react';
import { Button } from 'shared/Button';

import styles from './styles.module.scss';

export interface ReportDeleteProps {
  togglePopup: (e: any, value: boolean) => void;
  deleteReport: (e: any) => void;
}

export const ReportDelete: React.SFC<ReportDeleteProps> = ({ togglePopup, deleteReport }) => {
  return (
    <div className={styles.modalContent}>
      <p className={styles.subTitle}>
        Are you sure that you want to delete this report? This action can not be
        undone.
      </p>

      <div className={styles.buttonsBlock}>
        <Button
          className={styles.cancelButton}
          onClick={(e) => togglePopup(e, false)}
        >
          CANCEL
        </Button>
        <Button
          className={styles.submitButton}
          onClick={(e) => deleteReport(e)}
        >
          DELETE
        </Button>
      </div>
    </div>
  );
};
