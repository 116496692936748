// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_list__189JJ {\n  display: flex;\n  flex-direction: column; }\n  .styles_list__189JJ .styles_list_item__2P3Vk:nth-child(odd) {\n    background-color: #fcfcfc; }\n\n.styles_list_item__2P3Vk {\n  display: flex;\n  padding: 20px;\n  flex-shrink: 0; }\n\n.styles_list_item_title__1_jO9 {\n  width: 270px;\n  flex-shrink: 0; }\n\n.styles_list_item_text__2bWdl {\n  flex: 1 1;\n  flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"list": "styles_list__189JJ",
	"list_item": "styles_list_item__2P3Vk",
	"list_item_title": "styles_list_item_title__1_jO9",
	"list_item_text": "styles_list_item_text__2bWdl"
};
module.exports = exports;
