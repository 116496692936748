// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TablePagination_container__3fozt {\n  background-color: #ffffff;\n  border: 1px solid #e7e7e7;\n  box-shadow: 0px -3px 9px rgba(0, 0, 0, 0.07);\n  padding: 0 22px;\n  border-radius: 0 0 4px 4px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 50px; }\n\n.TablePagination_bottomBlock__18hIo {\n  width: 100%;\n  height: 52px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n\n.TablePagination_pageCounter__2G1lv {\n  display: flex;\n  align-items: center;\n  min-width: 130px; }\n\n.TablePagination_rowToggler__UK7OI {\n  display: flex;\n  align-items: center; }\n  .TablePagination_rowToggler__text__sVitg {\n    margin: 0 10px 0 0;\n    font-size: 14px;\n    color: #707070; }\n  .TablePagination_rowToggler__select__3Te4X {\n    width: 74px; }\n\n.TablePagination_field__3p4AB {\n  width: 32px;\n  height: 32px;\n  color: #707070;\n  background: #e6f6ed;\n  border-radius: 4px;\n  margin-right: 6px; }\n\n.TablePagination_pageNumbers__30WoI {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 28px 0 20px;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.175px;\n  color: #b3b3b3; }\n  .TablePagination_pageNumbers__30WoI .TablePagination_text__2NwJe {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #707070;\n    background: #e6f6ed;\n    border-radius: 4px;\n    margin-right: 6px; }\n", ""]);
// Exports
exports.locals = {
	"container": "TablePagination_container__3fozt",
	"bottomBlock": "TablePagination_bottomBlock__18hIo",
	"pageCounter": "TablePagination_pageCounter__2G1lv",
	"rowToggler": "TablePagination_rowToggler__UK7OI",
	"rowToggler__text": "TablePagination_rowToggler__text__sVitg",
	"rowToggler__select": "TablePagination_rowToggler__select__3Te4X",
	"field": "TablePagination_field__3p4AB",
	"pageNumbers": "TablePagination_pageNumbers__30WoI",
	"text": "TablePagination_text__2NwJe"
};
module.exports = exports;
