// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_error__1-Izr {\n  font-size: 12px;\n  line-height: 16px;\n  color: #f44336;\n  margin-bottom: 16px;\n  text-align: center; }\n\n.styles_errorBlock__3flAC {\n  display: flex;\n  justify-content: space-between; }\n\n.styles_errorText__3qL8Q {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  /* identical to box height */\n  text-align: center;\n  color: #db4437;\n  margin: 12px; }\n\n.styles_errorImg__1ClJy {\n  width: 16px;\n  height: 18px;\n  margin: 12px; }\n", ""]);
// Exports
exports.locals = {
	"error": "styles_error__1-Izr",
	"errorBlock": "styles_errorBlock__3flAC",
	"errorText": "styles_errorText__3qL8Q",
	"errorImg": "styles_errorImg__1ClJy"
};
module.exports = exports;
