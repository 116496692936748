// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_recoveryForm__38SDI {\n  width: 348px; }\n\n.styles_back__3pm32 {\n  display: flex;\n  margin-bottom: 16px; }\n\n.styles_backImg__I2PKO {\n  cursor: pointer; }\n\n.styles_title__aE0p4 {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px; }\n\n.styles_text__2wh_d {\n  font-family: Roboto;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #9b9b9b;\n  margin-bottom: 38px; }\n\n.styles_error__30WRn {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px; }\n\n.styles_submitButton__1SQH_ {\n  margin-top: 36px; }\n", ""]);
// Exports
exports.locals = {
	"recoveryForm": "styles_recoveryForm__38SDI",
	"back": "styles_back__3pm32",
	"backImg": "styles_backImg__I2PKO",
	"title": "styles_title__aE0p4",
	"text": "styles_text__2wh_d",
	"error": "styles_error__30WRn",
	"submitButton": "styles_submitButton__1SQH_"
};
module.exports = exports;
