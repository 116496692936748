// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__3Tip9 {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n  margin-bottom: 30px; }\n", ""]);
// Exports
exports.locals = {
	"container": "styles_container__3Tip9"
};
module.exports = exports;
