// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes styles_load__3Gx5Q {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n\n.styles_wrapper__33Xym {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 9; }\n\n.styles_container__3wIiO {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n  height: 100%;\n  width: 100%; }\n\n.styles_loader__3ogfR {\n  font-size: 10px;\n  text-indent: -9999em;\n  border-radius: 50%;\n  border-width: 5px;\n  border-style: solid;\n  border-color: #3b8757 #3b8757 #3b8757 #769e85;\n  transform: translateZ(0);\n  animation: styles_load__3Gx5Q 1.1s infinite linear; }\n  .styles_loader_size_default__2dLV3 {\n    width: 40px;\n    height: 40px; }\n  .styles_loader_size_button__2G7Nq {\n    width: 18px;\n    height: 18px;\n    border-width: 3px; }\n", ""]);
// Exports
exports.locals = {
	"wrapper": "styles_wrapper__33Xym",
	"container": "styles_container__3wIiO",
	"loader": "styles_loader__3ogfR",
	"load": "styles_load__3Gx5Q",
	"loader_size_default": "styles_loader_size_default__2dLV3",
	"loader_size_button": "styles_loader_size_button__2G7Nq"
};
module.exports = exports;
