// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_recoveryForm__udisg {\n  width: 348px; }\n\n.styles_back__2wJ87 {\n  display: flex;\n  margin-bottom: 16px; }\n\n.styles_backImg__3aiYz {\n  cursor: pointer; }\n\n.styles_title__5n3oh {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px; }\n\n.styles_text__V4sG8 {\n  font-family: Roboto;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #9b9b9b;\n  margin-bottom: 38px; }\n\n.styles_error__1XMDC {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px; }\n\n.styles_submitButton__17a7j {\n  margin-top: 36px; }\n", ""]);
// Exports
exports.locals = {
	"recoveryForm": "styles_recoveryForm__udisg",
	"back": "styles_back__2wJ87",
	"backImg": "styles_backImg__3aiYz",
	"title": "styles_title__5n3oh",
	"text": "styles_text__V4sG8",
	"error": "styles_error__1XMDC",
	"submitButton": "styles_submitButton__17a7j"
};
module.exports = exports;
