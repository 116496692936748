// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Details_details__34c7R {\n  background: white;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  margin-bottom: 20px; }\n  .Details_details__34c7R summary::-webkit-details-marker {\n    display: none; }\n  .Details_details__34c7R summary {\n    list-style-type: none;\n    list-style: none; }\n  .Details_details_summary__3vkcO {\n    display: flex;\n    height: 48px;\n    align-items: center;\n    padding-left: 16px; }\n    .Details_details_summary__3vkcO button {\n      margin: 16px; }\n  .Details_details_body___3kBZ {\n    display: flex;\n    flex-direction: column;\n    padding: 16px; }\n", ""]);
// Exports
exports.locals = {
	"details": "Details_details__34c7R",
	"details_summary": "Details_details_summary__3vkcO",
	"details_body": "Details_details_body___3kBZ"
};
module.exports = exports;
