// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_userBlock__jWxV2 {\n  display: flex;\n  align-items: center;\n  cursor: pointer; }\n\n.styles_userName__38nZi {\n  margin: 0 16px 0 0;\n  font-size: 14px;\n  color: #fff; }\n\n.styles_userContent__1D2zH {\n  position: relative;\n  width: 48px;\n  height: 48px; }\n\n.styles_userImage__3y04s {\n  width: 48px;\n  height: 48px;\n  object-fit: contain;\n  border-radius: 50%;\n  background-color: white; }\n\n.styles_userDropdown__2KZwh {\n  z-index: 10;\n  position: absolute;\n  right: 0;\n  top: 100%;\n  width: 180px;\n  background-color: #fff;\n  border-radius: 4px;\n  box-shadow: 0 10px 30px 0 rgba(67, 67, 67, 0.24); }\n  .styles_userDropdown__2KZwh a {\n    color: #3e3e3e; }\n  .styles_userDropdown__2KZwh .styles_userDropdownItem__1F6ar {\n    height: 45px;\n    border-radius: 4px; }\n    .styles_userDropdown__2KZwh .styles_userDropdownItem__1F6ar:hover {\n      background: #f2faf6; }\n\n.styles_button__21BVr {\n  height: 100%;\n  width: 100%;\n  font-size: 14px;\n  font-family: Roboto;\n  padding: 10px;\n  margin: auto auto;\n  text-align: center;\n  vertical-align: middle; }\n", ""]);
// Exports
exports.locals = {
	"userBlock": "styles_userBlock__jWxV2",
	"userName": "styles_userName__38nZi",
	"userContent": "styles_userContent__1D2zH",
	"userImage": "styles_userImage__3y04s",
	"userDropdown": "styles_userDropdown__2KZwh",
	"userDropdownItem": "styles_userDropdownItem__1F6ar",
	"button": "styles_button__21BVr"
};
module.exports = exports;
