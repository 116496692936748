// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadButton_container__3n_R5 {\n  width: 108px;\n  height: 108px;\n  min-height: 108px;\n  background-position: center;\n  background-repeat: no-repeat;\n  border: 1px solid #e7e7e7;\n  border-radius: 3px;\n  box-sizing: border-box;\n  display: flex;\n  position: relative; }\n\n.UploadButton_pic__2Z3PW {\n  width: 100%;\n  height: 100%;\n  margin-bottom: 20px; }\n\n.UploadButton_addPhoto__32GK8 {\n  width: 100%;\n  height: 100%;\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1; }\n\n.UploadButton_avatarLabel__3rhe8 {\n  display: block;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center; }\n", ""]);
// Exports
exports.locals = {
	"container": "UploadButton_container__3n_R5",
	"pic": "UploadButton_pic__2Z3PW",
	"addPhoto": "UploadButton_addPhoto__32GK8",
	"avatarLabel": "UploadButton_avatarLabel__3rhe8"
};
module.exports = exports;
