// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PhotoControlContainer_field_container__2a3j0 {\n  display: flex;\n  flex-shrink: 0;\n  margin-bottom: 10px; }\n\n.PhotoControlContainer_field_preview__2IK7u {\n  min-height: 120px;\n  flex-shrink: 1 !important; }\n\n.PhotoControlContainer_field_preview_container__G1N52 {\n  width: 108px;\n  height: 108px;\n  border-radius: 3px;\n  overflow: hidden;\n  margin: 6px;\n  flex-grow: 0;\n  flex-shrink: 0;\n  object-fit: cover; }\n\n.PhotoControlContainer_field_textarea__2OFSq {\n  min-height: 120px !important;\n  height: 120px !important; }\n\n.PhotoControlContainer_field_preview_image__GjdbM {\n  height: 100%;\n  width: 100%; }\n\n.PhotoControlContainer_field_delete__k4fBB {\n  opacity: 0.5; }\n\n.PhotoControlContainer_field_delete_button__3FKgZ {\n  display: flex;\n  flex-direction: column;\n  min-width: 80px;\n  padding: 8px;\n  align-items: center;\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 16px;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"field_container": "PhotoControlContainer_field_container__2a3j0",
	"field_preview": "PhotoControlContainer_field_preview__2IK7u",
	"field_preview_container": "PhotoControlContainer_field_preview_container__G1N52",
	"field_textarea": "PhotoControlContainer_field_textarea__2OFSq",
	"field_preview_image": "PhotoControlContainer_field_preview_image__GjdbM",
	"field_delete": "PhotoControlContainer_field_delete__k4fBB",
	"field_delete_button": "PhotoControlContainer_field_delete_button__3FKgZ"
};
module.exports = exports;
