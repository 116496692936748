// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_header__2xE15 {\n  height: 80px;\n  display: flex;\n  flex-shrink: 0;\n  flex-grow: 0;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 34px;\n  background: linear-gradient(179.06deg, #357450 -21.99%, #3eae6e 177.01%); }\n\n.styles_logo__9YfB- {\n  width: 64px; }\n", ""]);
// Exports
exports.locals = {
	"header": "styles_header__2xE15",
	"logo": "styles_logo__9YfB-"
};
module.exports = exports;
