import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { actions } from '../App/store/duck';
import {
  failureSelector,
  isLoadingSelector,
  isLoginSelector,
  recoveryViewSelector
} from '../App/store/selectors';
import LoginForm from './components/LoginForm';
import Recovery from './containers/Recovery';
import styles from './styles.module.scss';

const logo = require('./assets/img/logo.svg');

interface IProps {
  isLogin: boolean;
  isLoading: boolean;
  failure?: any;
  logIn: (value: any) => void;
  recoveryView: boolean;
  showRecovery: () => void;
}

const mapStateToProp = state => {
  return {
    isLogin: isLoginSelector(state),
    failure: failureSelector(state),
    isLoading: isLoadingSelector(state),
    recoveryView: recoveryViewSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logIn: values => dispatch(actions.logIn(values)),
    showRecovery: () => dispatch(actions.showRecovery())
  };
};

class Login extends React.Component<IProps> {
  render() {
    const {
      isLogin,
      logIn,
      failure,
      isLoading,
      recoveryView,
      showRecovery
    } = this.props;

    if (isLogin) {
      return <Redirect path="/login" to="/surveys" />;
    }

    return (
      <div className={styles.login}>
        <div className={styles.form}>
          <img src={logo} alt="logo" className={styles.logo} />
          {recoveryView ? (
            <Recovery />
          ) : (
            <LoginForm
              logIn={logIn}
              failure={failure}
              isLoading={isLoading}
              showRecovery={showRecovery}
            />
          )}
        </div>
        <div className={styles.welcome}>
          <h1 className={styles.welcome_title}>
            Welcome to <span>GIR Solutions Platform</span>
          </h1>
          <p className={styles.welcome_text}>
            Inspection Data Management Portal
          </p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Login);
