// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/img/photo_add.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".styles_container__2zGnU {\n  width: 100%;\n  height: 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  box-sizing: border-box;\n  object-fit: contain; }\n\n.styles_pic__3Fbx4 {\n  width: 100%;\n  height: 100%;\n  margin-bottom: 10px; }\n\n.styles_addPhoto__3CE2I {\n  width: 100%;\n  height: 100%;\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1; }\n\n.styles_avatarLabel__EhE6q {\n  display: block;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  object-fit: contain; }\n\n.styles_avatarLabel__EhE6q:hover {\n  background: rgba(0, 0, 0, 0.37) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  object-fit: contain; }\n\n.styles_deleteButton__1tQQv {\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.0357143em;\n  color: #328757; }\n", ""]);
// Exports
exports.locals = {
	"container": "styles_container__2zGnU",
	"pic": "styles_pic__3Fbx4",
	"addPhoto": "styles_addPhoto__3CE2I",
	"avatarLabel": "styles_avatarLabel__EhE6q",
	"deleteButton": "styles_deleteButton__1tQQv"
};
module.exports = exports;
