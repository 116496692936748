import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';

import * as constants from './constants';
import { IAssignments, IAssignmentsReducers } from './interfaces';

export const actions = {
  getAssignments: createAction(constants.GET_ASSIGNMENTS),
  getAssignmentsSuccess: createAction(constants.GET_ASSIGNMENTS_SUCCESS),
  getAssignmentsFailure: createAction(constants.GET_ASSIGNMENTS_FAILURE),
  clearAssignments: createAction(constants.CLEAR_ASSIGNMENTS),
  clearAssignment: createAction(constants.CLEAR_ASSIGNMENT),
  getAssignment: createAction(constants.GET_ASSIGNMENT),
  getAssignmentSuccess: createAction(constants.GET_ASSIGNMENT_SUCCESS),
  getAssignmentFailure: createAction(constants.GET_ASSIGNMENT_FAILURE),
  addAssignment: createAction(constants.ADD_ASSIGNMENT),
  addAssignmentSuccess: createAction(constants.ADD_ASSIGNMENT_SUCCESS),
  addAssignmentFailure: createAction(constants.ADD_ASSIGNMENT_FAILURE),
  editAssignment: createAction(constants.EDIT_ASSIGNMENT),
  editAssignmentSuccess: createAction(constants.EDIT_ASSIGNMENT_SUCCESS),
  editAssignmentFailure: createAction(constants.EDIT_ASSIGNMENT_FAILURE),
  deleteAssignment: createAction(constants.DELETE_ASSIGNMENT),
  deleteAssignmentSuccess: createAction(constants.DELETE_ASSIGNMENT_SUCCESS),
  deleteAssignmentFailure: createAction(constants.DELETE_ASSIGNMENT_FAILURE)
};

const assignmentsIsLoading = (
  state = false,
  action: PayloadedAction
): boolean => {
  switch (action.type) {
    case constants.GET_ASSIGNMENTS:
    case constants.GET_ASSIGNMENT:
      return true;
    case constants.GET_ASSIGNMENTS_SUCCESS:
    case constants.GET_ASSIGNMENTS_FAILURE:
    case constants.GET_ASSIGNMENT_SUCCESS:
    case constants.GET_ASSIGNMENT_FAILURE:
      return false;
    default:
      return state;
  }
};

const initialStateAssignments = {
  assignmentsData: {
    count: 0,
    offset: 0,
    limit: 100,
    search: '',
    next: '',
    sortBy: '',
    previous: '',
    results: [],
    isEnd: false,
  },
  currentAssignment: null,
  failure: null,
  loader: false,
};

export const assignmentsReducer = (
  state = initialStateAssignments,
  action: PayloadedAction
): IAssignments => {
  switch (action.type) {
    // case constants.ADD_ASSIGNMENT:
    case constants.EDIT_ASSIGNMENT:
      return {
        ...state,
        loader: true
      };
    case constants.GET_ASSIGNMENTS_SUCCESS:
      const isEndStatus = !!action.payload.results.length;
      return {
        ...state,
        assignmentsData: {
          ...action.payload,
          results: [
            ...state.assignmentsData.results,
            ...action.payload.results,
          ],
          isEnd: isEndStatus,
        },
      };
    case constants.GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        currentAssignment: action.payload
      };
    case constants.ADD_ASSIGNMENT_SUCCESS:
    case constants.EDIT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loader: false
      };
    case constants.GET_ASSIGNMENTS_FAILURE:
    case constants.EDIT_ASSIGNMENT_FAILURE:
    case constants.GET_ASSIGNMENT_FAILURE:
    case constants.ADD_ASSIGNMENT_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false
      };
    case constants.CLEAR_ASSIGNMENTS:
      return {
        ...state,
        assignmentsData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          sortBy: '',
          previous: '',
          results: [],
          isEnd: false,
        },
        currentAssignment: null,
        failure: null,
        loader: false,
      };
    case constants.CLEAR_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: null,
        failure: null,
        loader: false
      };
    default:
      return state;
  }
};

const assignmentsReducers = combineReducers<IAssignmentsReducers>({
  assignmentsIsLoading,
  assignmentsReducer
});

export default assignmentsReducers;
