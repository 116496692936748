// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_field_label__27eP0 {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n  margin-bottom: 12px;\n  color: #9b9b9b;\n  margin-bottom: 12px; }\n", ""]);
// Exports
exports.locals = {
	"field_label": "styles_field_label__27eP0"
};
module.exports = exports;
