// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_editButton__2XTAC {\n  cursor: pointer;\n  width: 56px;\n  height: 56px;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  bottom: 70px;\n  right: 30px;\n  border-radius: 50%;\n  background: #469367;\n  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12); }\n\n.styles_editButton__2XTAC:hover {\n  background: #41b673; }\n", ""]);
// Exports
exports.locals = {
	"editButton": "styles_editButton__2XTAC"
};
module.exports = exports;
