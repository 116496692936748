// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_container__idc7t {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0; }\n  .styles_container_margin__2g7Hx {\n    margin-bottom: 20px; }\n\n.styles_field__aRdBl {\n  display: flex;\n  width: 100%;\n  background: white;\n  border: 1px solid #e7e7e7;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.styles_field__aRdBl:focus-within {\n  border-color: #328757; }\n\n.styles_error__5lPJS {\n  border-color: #db4437; }\n\n.styles_field_input__N6Nuu {\n  padding: 16px;\n  color: #3e3e3e;\n  font-size: 14px;\n  line-height: 16px;\n  width: 100%;\n  min-width: 40px;\n  height: 46px; }\n\n.styles_adornment__2UwP9 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 46px;\n  min-width: 46px;\n  flex-shrink: 0;\n  flex-grow: 0; }\n\n.styles_errorBlock__1z2fV {\n  display: flex;\n  justify-content: space-between; }\n\n.styles_errorText__1qIQx {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  /* identical to box height */\n  text-align: center;\n  color: #db4437;\n  margin: 12px; }\n\n.styles_errorImg__1Jps_ {\n  width: 16px;\n  height: 18px;\n  margin: 12px; }\n", ""]);
// Exports
exports.locals = {
	"container": "styles_container__idc7t",
	"container_margin": "styles_container_margin__2g7Hx",
	"field": "styles_field__aRdBl",
	"error": "styles_error__5lPJS",
	"field_input": "styles_field_input__N6Nuu",
	"adornment": "styles_adornment__2UwP9",
	"errorBlock": "styles_errorBlock__1z2fV",
	"errorText": "styles_errorText__1qIQx",
	"errorImg": "styles_errorImg__1Jps_"
};
module.exports = exports;
